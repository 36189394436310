import { Slide, Zoom } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const TransitionZoom = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return (
        <Zoom
            ref={ref}
            {...props}
        />
    );
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return (
        <Slide
            direction="up"
            ref={ref}
            {...props}
        />
    );
});

const motionVariants = {
    fromRight: {
        initial: { opacity: 0, x: 100 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -100 },
    },
    fromLeft: {
        initial: { opacity: 0, x: -100 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
    },
    fromTop: {
        initial: { opacity: 0, y: -100 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 100 },
    },
    fromBottom: {
        initial: { opacity: 0, y: 100 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -100 },
    },
};

interface TransitionFramerProps {
    children: React.ReactNode;
    keyComponent: string;
    direction: 'fromRight' | 'fromLeft' | 'fromTop' | 'fromBottom';
}

const TransitionComponent: React.FC<TransitionFramerProps> = ({ children, keyComponent, direction }) => {
    const variants = motionVariants[direction];

    return (
        <AnimatePresence mode="wait">
            <motion.div
                key={keyComponent}
                initial={variants.initial}
                animate={variants.animate}
                exit={variants.exit}
                transition={{ duration: 0.3 }}
                style={{ width: '100%', overflow: 'hidden' }}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    );
};

export { Transition, TransitionComponent, TransitionZoom };
